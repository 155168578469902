<template>
  <div class="row">
    <b-modal id="display-history-invoice-modal" ref="display-history-invoice-modal" hide-header hide-header-close
      scrollable size="lg">
      <div class="row mx-0 py-7">
        <div class="col-12  mx-0 py-2 text-xl">
          <div class="col-12 py-2 px-0 text-center text-xl font-weight-boldest navy-blue-border-bottom-1px bg-fine">
            {{$t('invoice.invoice_history')}}
          </div>
          <template>
            <div class="col-12 d-flex px-0 justify-content-center">
              <div
                class="w-50px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px navy-blue-border-left-1px">
                #
              </div>
              <div
                class="w-200px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px">
                {{ $t('following.date') }}
              </div>
              <div
                class="w-200px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px">
                {{$t('invoice.person_performing_the_transaction')}}
              </div>
              <div
                class="w-200px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px">
                {{$t('invoice.prev_status')}}
              </div>
              <div
                class="w-200px py-1 text-center font-weight-bold navy-blue-border-bottom-1px navy-blue-border-right-1px">
                {{$t('invoice.current_status')}}
              </div>
            </div>
            <div v-if="item!=null&&item.invoice_history.length>0">
              <div v-for="(history, key) in item.invoice_history" :key="key"
                class="col-12 d-flex px-0 justify-content-center navy-blue-border-bottom-1px">
                <div
                  class="w-50px py-1 text-center font-weight-bold navy-blue-border-right-1px navy-blue-border-left-1px">
                  {{ key + 1 }}
                </div>
                <div class="w-200px py-1 text-center font-weight-bold navy-blue-border-right-1px">
                  {{ history.created_at }}
                </div>
                <div class="w-200px py-1 text-center font-weight-bold navy-blue-border-right-1px">
                  {{ history.user.name }}
                </div>
                <div class="w-200px py-1 text-center font-weight-bold navy-blue-border-right-1px">
                  {{ history.invoice_prev_status.translation.name }}
                </div>
                <div class="w-200px py-1 text-center font-weight-bold navy-blue-border-right-1px">
                  {{ history.invoice_last_status.translation.name }}
                </div>
              </div>
            </div>
            <div v-else class="col-12 d-flex px-0 justify-content-center">
              {{$t('invoice.invoice_history_not_found')}}
            </div>
          </template>
        </div>
      </div>
      <template #modal-footer>
        <div class="col-12 text-center">
          <button class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5"
            @click="closeModal('display-history-invoice-modal')">
            {{ $t('general.close') }}
          </button>
        </div>
      </template>
    </b-modal>
    <div class="col-md-12 d-flex justify-content-start items-center">
      <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)">
        <i class="fa fa-chevron-left"></i>
      </span>
      <h3>{{
          item && +item.is_service === 0 ? $t('invoice.accepted_invoice_detail') : $t('invoice.accepted_invoice_detail')
      }}</h3>
    </div>
    <div class="col-md-12 ">
      <dashboard-box v-if="item">
        <template v-slot:preview>
          <div class="px-3 py-3">
            <div class="row items-start">

              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0 font-weight-boldest">
                <div class="col-4 py-4 items-start">
                  <img id="" :src="item.image_link" class="w-75px h-75px object-cover primary-border-1px border-15px">
                </div>
                <div class="col-8 items-end">
                  <p><strong>{{ $t('invoice.created_user') }}</strong> : {{
                      item.created_user ? item.created_user.name : ''
                  }}</p>
                  <p><strong>{{ $t('invoice.date') }}</strong> : {{ item.created_at | momentDateFormat }}</p>
                  <p><strong>{{ $t('invoice.company_name') }}</strong> : {{ item.supplier_company.name_for_accounting }}
                  </p>

                </div>

                

              </div>

              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0 font-weight-boldest">

                <div class="col-12">
                  <p><strong>{{ $t('invoice.invoice_number') }}</strong> : {{ item.invoice_no }}</p>

                </div>
                <div class="col-12">
                  <p><strong>{{ $t('invoice.invoice_date') }}</strong> : {{ item.invoice_date | momentDateFormat }}</p>

                </div>
                <div class="col-12 d-lg-none d-md-block">
                  <p><strong>{{ $t('invoice.vat') }}</strong> : {{ item.calculated_vat | priceFormat }}</p>
                </div>
                
                <div class="col-12 d-flex justify-content-start">
                  <text-input :disabled="true" :is-inline="false" :model="item.invoice_type.translations[0].name"
                    :title="$t('invoice.invoice_type')"></text-input>

                  <text-input :disabled="true" :is-inline="false" :model="item.invoice_category.translations[0].name"
                    :title="`Tipi`"></text-input>
                </div>
                
                <div v-if="item.parent_invoice" class="col-12">
                  <text-input
                      :disabled="true"
                      :model.sync="item.parent_invoice.invoice_no"
                      :required="true"
                      :title="$t('invoice.parent_name_invoice')"
                  ></text-input>
                </div>

              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0 font-weight-boldest">
                <div class="col-12  d-lg-flex d-md-none">
                  <div class="col-8 d-lg-block d-md-none">
                    <p><strong>{{ $t('invoice.vat') }}</strong> : {{ item.calculated_vat | priceFormat }}
                      {{ invoiceCurrency }}</p>
                    <p><strong>{{ $t('order.discount') }}</strong> : {{ item.total_discount | priceFormat }}
                      {{ invoiceCurrency }}</p>
                    <p><strong>{{ $t('invoice.total') }}</strong> : {{ item.total_amount | priceFormat }}
                      {{ invoiceCurrency }}</p>
                    <p><strong>{{ $t('invoice.amount_to_be_paid') }}</strong> : {{
                        item.amount_to_be_paid | priceFormat
                    }} {{ invoiceCurrency }}</p>
                  </div>
                  <div class="col-4 d-lg-block ml-0 pl-0">
                    <qrcode-vue :size="100" :value="item.qr_code_link" renderAs="svg"></qrcode-vue>
                    <span>{{ item.code }}</span>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0 font-weight-boldest" v-if="item.accounting_description&&item.accounting_description!=null">
                <p><strong>{{ $t('invoice.accounting_description') }}</strong> : {{ item.accounting_description }}</p>
              </div>
              <div class="col-12 row d-flex ml-2" v-if="item.reflect_invoice.length>0">
                  <div class="col-12 col-md-4 col-sm-12">
                      <p>
                        <strong>{{ $t('invoice.generated_reflection_invoices') }}</strong>
                      </p>
                      <p  v-for="reflectInvoice,index in item.reflect_invoice" :key="index">
                        <span v-if="reflectInvoice.invoice_no">{{ reflectInvoice.invoice_no  }}  
                          <i class="fa fa-eye" role="button" @click="redirectToInvoiceDetails(reflectInvoice.id)">
                          </i>
                        </span>
                      </p>
                    </div>
                    <div class="col-12 col-md-4 col-sm-12">
                    <p>
                      <strong>{{ $t('invoice.pending_reflection_invoices') }}</strong>
                    </p>
                    <p v-for="reflectInvoice,index in item.reflect_invoice" :key="index"> 
                      <span v-if="reflectInvoice.code">{{ $t('invoice.code') }}: {{ reflectInvoice.code }}
                        <i class="fa fa-eye" role="button" @click="redirectToInvoiceDetails(reflectInvoice.id)">
                        </i>
                      </span>
                    </p>
                </div>
              </div>
              
              <div class="col-12 row mx-0 font-weight-boldest pb-1 pt-1">
                <i class="fa fa-eye" role="button" @click="showHistoryInvoiceModal()">
                  {{$t('invoice.invoice_history')}}
                </i>
              </div>
            </div>
            <div class="relative">
              <div class="w-100 px-5 py-3 h-50vh">
                <div class="w-100 col-12 border-15px navy-blue-border-1px mx-3 mt-5 mb-1">
                  <table class="table w-100 border-15px mb-0">
                    <thead class="w-100">
                      <tr>
                        <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                          <div class="text-right text-lg pr-10">
                            {{ $t('invoice.code') }}
                          </div>
                        </th>
                        <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                          <div class="d-flex justify-content-center items-center text-lg">
                            {{ $t('invoice.code_name') }}
                            <info-tooltip :helper-text="$t('invoice.please_enter_manually')"></info-tooltip>
                          </div>
                        </th>
                        <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                          <div class="d-flex justify-content-center items-center text-lg">
                            {{ $t('invoice.order_number') }}
                          </div>
                        </th>
                        <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                          <div class="d-flex justify-content-center items-center text-lg">
                            {{ $t('invoice.amount') }}
                            <info-tooltip :helper-text="$t('invoice.please_enter_manually')"></info-tooltip>
                          </div>
                        </th>
                        <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                          <div class="d-flex justify-content-center items-center text-lg">
                            {{ $t('invoice.unit') }}
                            <info-tooltip :helper-text="$t('invoice.please_enter_manually')"></info-tooltip>
                          </div>
                        </th>
                        <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                          <div class="d-flex justify-content-center items-center text-lg">
                            {{ $t('invoice.unit_price') }}
                            <info-tooltip :helper-text="$t('invoice.please_enter_manually')"></info-tooltip>
                          </div>
                        </th>
                        <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                          <div class="d-flex justify-content-center items-center text-lg">
                            {{ $t('invoice.vat_amount') }}
                            <info-tooltip :helper-text="$t('invoice.please_enter_manually')"></info-tooltip>
                          </div>
                        </th>

                        <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                          <div class="d-flex justify-content-center items-center text-lg">
                            {{ $t('invoice.withholding_amount') }}
                            <info-tooltip :helper-text="$t('invoice.please_enter_manually')"></info-tooltip>
                          </div>
                        </th>
                        <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                          <div class="d-flex justify-content-center items-center text-lg">
                            {{ $t('invoice.index_amount') }}
                            <info-tooltip :helper-text="$t('invoice.withholding_extra_text')"></info-tooltip>
                          </div>
                        </th>
                        <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                          <div class="d-flex justify-content-center items-center text-lg">
                            {{ $t('invoice.amount_total') }}
                            <info-tooltip :helper-text="$t('invoice.please_enter_manually')"></info-tooltip>
                          </div>
                        </th>

                        <th class="navy-blue-border-bottom-1px">
                          <div class="d-flex justify-content-center items-center text-lg">
                            {{ $t('order.description') }}
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="navy-blue-bottom-1px">
                      <template v-for="(item, key) in item.transactions">
                        <tr>
                          <td class="navy-blue-border-right-1px">
                            <input v-model="item.line_code" :disabled="true" class="form-control d-inline-block mb-5"
                              type="text">
                          </td>

                          <td class="navy-blue-border-right-1px items-start">
                            <input v-model="item.line_code_name" :disabled="true"
                              class="form-control d-inline-block mb-5" type="text">
                          </td>

                          <td class="navy-blue-border-right-1px items-start">
                            <input v-if="item.waybillable" v-model="item.waybillable.order_number" :disabled="true"
                              class="form-control d-inline-block mb-5" type="text">
                          </td>

                          <td class="navy-blue-border-right-1px items-start">
                            <input v-model="item.amount" :disabled="true" class="form-control d-inline-block mb-5"
                              type="number">
                          </td>

                          <td class="navy-blue-border-right-1px items-start">
                            <custom-multi-select :disabled="true" :input-width="`65px`" :is-inline="false"
                              :item-per-row="1" :max="1" :model.sync="item.amount_unit_id" :not-list="true"
                              :options="options.unitOptions" :required="false" :title="null"
                              name=""></custom-multi-select>
                          </td>
                          <td class="navy-blue-border-right-1px">
                            <input v-model="item.unit_price" :disabled="true"
                              class="form-control form-control-sm d-inline-block" step="1" type="number">
                          </td>
                          <td class="navy-blue-border-right-1px">
                            {{ item.vat_price | priceFormat }}
                          </td>
                          <td class="navy-blue-border-right-1px">
                            {{ item.withholding_amount | priceFormat }}
                          </td>
                          <td class="navy-blue-border-right-1px">
                            {{ item.price | priceFormat }}
                          </td>
                          <td class="navy-blue-border-right-1px">
                            {{ item.total_price | priceFormat }}
                          </td>
                          <td class="">
                            <input v-model="item.description" class="form-control form-control-sm" type="text">
                            <span></span>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>



                <div
                  class="float-right mb-n20 w-50 lg:w-35 md:w-50 sm:w-50 py-3 px-5 border-15px navy-blue-border-1px bg-white"
                  style="bottom: 0; right: 0;">

                  <invoice-layout 
                    :buying-type-options="options.receiveTypeOptions"
                    :buying_type_id.sync="item.invoice_receiving_type_id" 
                    :currency-options="options.currencyOptions"
                    :currency-rates="currencyRates" 
                    :currency_unit_id.sync="item.currency_unit_id"
                    :discount.sync="item.total_discount" 
                    :exchange.sync="item.exchange_rate" 
                    :item="item"
                    :transactions="item.transactions" 
                    :vat-options="vatRateOptions"
                    :vat-rates="vat_rates"
                    ></invoice-layout>
                </div>



              </div>
            </div>
          </div>
          <div class="row col-12 justify-content-center mt-10 pt-10" style="display: inline-flex;margin-top: 5% !important;">
            <div class="col-2 text-center">
              
              <span v-if="deleteInvoices()"
                class="px-3 py-1 green-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm mr-2"
                @click="deleteInvoice">
                <span v-html="getIconByKey('icons.waybill.delete_2', {
                  class: 'w-20px h-20px object-cover d-inline-block opacity-75'
                })">
                </span>
                {{ $t('invoice.delete_invoice').toUpperCase() }}
              </span>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TextInput from "@/assets/components/inputs/TextInput";
import { mapGetters, mapMutations } from 'vuex'
import QrcodeVue from 'qrcode.vue'
import moment from "moment";


import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import NumberInput from "@/assets/components/inputs/NumberInput";
import FileInput from "@/assets/components/inputs/FileInput";
import DataTable from "@/assets/components/dataTable/DataTable";
import InfoTooltip from "@/assets/components/widget/InfoTooltip";

import { GET_ITEM_DETAIL_BY_ID, GET_ITEMS, LOADING, RESET_VALUES, DELETE_ITEM_BY_ID } from "@/core/services/store/REST.module";
import { LARAVEL_DATE_FORMAT, LARAVEL_DATE_TIME_FORMAT, MOMENT_SYSTEM_DATE_FORMAT } from "@/core/config/constant";
import SelectInput from "@/assets/components/inputs/SelectInput";
import InvoiceLayout from "@/view/pages/invoice/layouts/InvoiceLayout";

export default {
  name: "InvoiceCreateEdit",
  components: {
    InvoiceLayout,
    SelectInput,
    FileInput,
    NumberInput,
    ButtonWithIcon,
    DatePickerInput,
    CustomMultiSelect,
    TextInput,
    DashboardBox,
    DataTable,
    QrcodeVue,
    InfoTooltip
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
    }),
    vatRateOptions() {
      let options = this.options.vatOptionsRaw || {};
      let data = options.filter(item => +item.type_id === 4)
      return this.convertArrayToObjectByKey(data, 'id', 'vat.rate');
    },
    invoiceCurrency() {
      if (this.form.currency_unit_id) {
        return this.options.currencyOptions[this.form.currency_unit_id]
      }
      return "";
    },
    formatedInvoiceHistory() {

    },
    transactionVatPrice() {
      return function (key) {
        let self = this;
        let rate = self.options.vatOptions.find(item => +item.id === +self.form.vat_rate_id).vat.rate;
        return self.transactionPrice(key) * rate / 100;
      }
    },
    vat_rates() {
      let self = this;
      console.log(self.item.transactions)
      return _.map(_.groupBy(self.item.transactions, 'vat_rate_id'), (o, idx) => {
        let vatRate = self.options.vatOptions[idx]
        console.log(o);
        return {
          rate: vatRate, sum: _.sumBy(o, 'vat_price')
        }
      })
    },
    transactionPrice() {
      return function (key) {
        let self = this;
        return self.item.transactions[key].amount * self.item.transactions[key].unit_price;
      }
    },
    transactionTotalPrice() {
      return function (key) {
        return this.transactionPrice(key) + this.transactionVatPrice(key);
      }
    },
    calculated_vat() {
      let sum = 0;
      let self = this;
      this.item.transactions.forEach(item => {
        let rate = self.options.vatOptions.find(item => +item.id === +self.item.vat_rate_id).vat.rate;
        sum += item.amount * item.unit_price * rate / 100;
      })
      return sum;
    },
    total_amount() {
      return this.item.total_amount_including_taxes;
    },
    total_discount() {
      return this.item.total_discount;
    },
    total() {
      let sum = 0;
      this.item.transactions.forEach(item => {
        sum += item.amount * item.unit_price;
      })
      return sum;
    }
  },
  data() {
    return {
      itemId: null,
      item: null,
      defaultCurrency: 'TL',
      waybills: [],
      instructionTypeOptionsRaw: [],
      vatDeducts: [],
      vatExceptions: [],
      vatOptions: [],
      currencyRates: [],
      options: {
        instructionTypeOptions: [],
        waybillableOptions: [],
        companyOptions: [],
        entryTypeOptions: {
          '0': this.$t('invoice.enter_manually'),
          '1': this.$t('invoice.scan_qr_code')
        },
        invoiceTypeOptions: [],
        invoiceCategoryOptions: [],
        vatOptions: [],
        vatRateOptions: [],
        currencyOptions: [],
        receiveTypeOptions: {},
        waybills: [],
        unitOptions: [],
        vatDeducts: [],
        vatExceptions: []
      },
      form: {
        is_withholding: '0',
        expense_type_id: null,

        file_names: ['image'],
        waybillable_id: null,
        supplier_company_id: null,
        invoice_type_id: null,
        invoice_category_id: null,
        invoice_kind_id: null,
        invoice_no: null,
        vat_id: 1,
        image: null,
        invoice_date: moment().format(LARAVEL_DATE_FORMAT),
        waybill_ids: [],
        currency_unit_id: '0',
        invoice_receiving_type_id: '0',
        exchange_rate: null,

        total_amount_including_taxes: 11,
        amount_to_be_paid: 11,
        total_discount: 11,
        calculated_vat: 11,
        total_amount: 11,
        withholding_amount: 11,
        withholding_amount_calculated_vat: 11,
      },
      items: { data: [] },
    };
  },
  methods: {
    ...mapMutations({}),
    deleteInvoice() {
      let self = this;
      let url = `api/invoice/${self.itemId}`;
      this.$store.dispatch(DELETE_ITEM_BY_ID,
        { url: url }
      ).then(result => {
        if (result.status) {
          self.sweetAlertSuccess(self.$t('general.successfully_deleted'));
          this.$route.go(-1);
        } else {
          let response = result.data.response;
          if (response.hasOwnProperty('data') && response.data.hasOwnProperty('message')) {
            self.sweetAlertError(response.data.message);
          } else {
            self.sweetAlertError(response.data);
          }
        }
      })
    },
    deleteInvoices(){
      let permission=false;
      let item=this.item;
      if(item.status_id==44){
        if(item.status_id==44&&item.parent_invoice.status_id==18&&this.isUserGranted('Invoice', ['removeChildReflectionSendLogoInvoice'], false,true)){
          permission=true;
        }else if(item.status_id==44&&item.parent_invoice.status_id==15&&this.isUserGranted('Invoice', ['removeReflectInvoice'], false,true)){
          permission=true;
        }
      }else{
        if(this.isUserGranted('Invoice', ['delete'], false)){
          permission=true;
        }
      }
      return permission;

    },
    showHistoryInvoiceModal() {
      this.$refs['display-history-invoice-modal'].show()
    },
    closeModal(name = "display-history-invoice-modal") {
      this.$refs[name].hide()
    },
    redirectToInvoiceDetails(invoiceId){
      this.$router.push({ name: 'invoice.details', params: { id: invoiceId } });
    }
  },
  created() {
    this.$store.commit(RESET_VALUES);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('general.dashboard'), route: "dashboard" },
      { title: this.$t('invoice.invoices'), route: "invoice.index" },
      { title: this.$t('invoice.edit') }
    ]);

    if (!this.isUserGranted('Invoice', ['view'])) {
      return false;
    }

    let self = this, promises = [];
    promises.push(this.$store.dispatch(GET_ITEMS, { url: 'api/units' }));
    promises.push(this.$store.dispatch(GET_ITEMS, { url: `api/supplier-companies`, filters: { all: true } }));
    promises.push(this.$store.dispatch(GET_ITEMS, { url: `api/currency` }));
    promises.push(this.$store.dispatch(GET_ITEMS, { url: `api/invoice-type` }));
    promises.push(this.$store.dispatch(GET_ITEMS, { url: `api/invoice-category` }));
    promises.push(this.$store.dispatch(GET_ITEMS, { url: `api/invoice-receive-type` }));
    promises.push(this.$store.dispatch(GET_ITEMS, {
      url: 'api/vat-rates', filters: {}
    }));

    this.itemId = this.$route.params.id;
    let type = this.$route.params.type;

    if (type && +type === 2) {
      promises.push(this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, { url: `api/logo-record/invoice/` + this.$route.params.id }));
    } else {
      promises.push(this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, { url: `api/invoice/` + this.$route.params.id }));
    }

    Promise.all(promises).then(responses => {
      if (responses[0].status) {
        self.options.unitOptions = self.convertArrayToObjectByKey(responses[0].data, 'id', 'translations.0.name');
      }
      if (responses[1].status) {
        self.options.companyOptions = self.convertArrayToObjectByKey(responses[1].data, 'id', 'name_for_accounting');
      }
      if (responses[2].status) {
        self.options.currencyOptions = self.convertArrayToObjectByKey(responses[2].data, 'id', 'code');
      }
      if (responses[3].status) {
        self.options.invoiceTypeOptions = self.convertArrayToObjectByKey(responses[3].data, 'id', 'translations.0.name');
      }
      if (responses[4].status) {
        self.options.invoiceKindOptions = self.convertArrayToObjectByKey(responses[4].data, 'id', 'translations.0.name');
      }
      if (responses[5].status) {
        self.options.receiveTypeOptions = self.convertArrayToObjectByKey(responses[5].data, 'id', 'translations.0.name');
      }
      if (responses[6].status) {
        self.options.vatOptions = self.convertArrayToObjectByKey(responses[6].data, 'id', 'vat.rate');
        self.options.vatOptionsRaw = responses[6].data;
      }

      if (responses[7].status) {
        self.item = responses[7].data
        if (self.item.invoice_history!=null&&self.item.invoice_history.length>0) {
          self.item.invoice_history.forEach((history, index) => {
            self.item.invoice_history[index]['created_at'] = moment(self.item.invoice_history[index]['created_at']).format(MOMENT_SYSTEM_DATE_FORMAT)
          })
        }
        self.item.created_at = moment(self.item.created_at, LARAVEL_DATE_TIME_FORMAT)
        self.item.invoice_date = moment(self.item.invoice_date, LARAVEL_DATE_TIME_FORMAT)
      }
    })
  },
}
</script>

<style lang="scss" scoped>
.h-50vh {
  height: 50vh !important;
}

.w-40 {
  width: 40% !important;
}
</style>
